import React, { useEffect, useState } from "react";
import {
  btc,
  doge,
  ethh,
  shiba,
  solana,
  tether,
  trx,
} from "../../../assets/images";
import axios from "axios";
import { API_URL } from "../../../constant";
import { useAuth } from "../../../context/auth-context";
import { TailSpin } from "react-loader-spinner";

const CryptoRate = () => {
  const { token } = useAuth();

  const [cryptoData, setCryptoData] = useState([
    {
      name: "Tether",
      abbr: "USDT",
      image: tether,
      oldRate: 0,
      newRate: "",
      adding: false,
      loading: false,
      refetchCount: 0,
    },
    {
      name: "Bitcoin",
      abbr: "BTC",
      image: btc,
      oldRate: 0,
      newRate: "",
      adding: false,
      loading: false,
      refetchCount: 0,
    },
    {
      name: "Ethereum",
      abbr: "ETH",
      image: ethh,
      oldRate: 0,
      newRate: "",
      adding: false,
      loading: false,
      refetchCount: 0,
    },
    {
      name: "Solana",
      abbr: "SOL",
      image: solana,
      oldRate: 0,
      newRate: "",
      adding: false,
      loading: false,
      refetchCount: 0,
    },
    {
      name: "Shiba",
      abbr: "SHIB",
      image: shiba,
      oldRate: 0,
      newRate: "",
      adding: false,
      loading: false,
      refetchCount: 0,
    },
    {
      name: "Dogecoin",
      abbr: "DOGE",
      image: doge,
      oldRate: 0,
      newRate: "",
      adding: false,
      loading: false,
      refetchCount: 0,
    },
    {
      name: "Tron",
      abbr: "TRX",
      image: trx,
      oldRate: 0,
      newRate: "",
      adding: false,
      loading: false,
      refetchCount: 0,
    },
  ]);

  // Fetch rates
  useEffect(() => {
    axios
      .get(`${API_URL}api/rates`)
      .then((response) => {
        const rates = response.data.data.rates;
        setCryptoData((prev) =>
          prev.map((crypto) => {
            const rateData = rates.find(
              (rate: any) => rate.abbr === crypto.abbr
            );
            return rateData ? { ...crypto, oldRate: rateData.rate } : crypto;
          })
        );
      })
      .catch((error) => console.error(error.response?.data));
  }, [cryptoData.map((crypto) => crypto.refetchCount).join(",")]);

  // Upload new rate
  const uploadRate = (abbr: string) => {
    setCryptoData((prev) =>
      prev.map((crypto) =>
        crypto.abbr === abbr ? { ...crypto, loading: true } : crypto
      )
    );

    const crypto = cryptoData.find((crypto) => crypto.abbr === abbr);
    if (crypto) {
      axios
        .post(
          `${API_URL}api/rates`,
          { [abbr]: crypto.newRate },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(() => {
          setCryptoData((prev) =>
            prev.map((crypto) =>
              crypto.abbr === abbr
                ? {
                    ...crypto,
                    loading: false,
                    newRate: "",
                    adding: false,
                    refetchCount: crypto.refetchCount + 1,
                  }
                : crypto
            )
          );
        })
        .catch((error) => {
          console.error(error.response?.data.message);
          setCryptoData((prev) =>
            prev.map((crypto) =>
              crypto.abbr === abbr ? { ...crypto, loading: false } : crypto
            )
          );
        });
    }
  };

  // Handle new rate input
  const handleRateChange = (abbr: string, value: string) => {
    const isNumber = !isNaN(Number(value)) && value.trim() !== "";
    setCryptoData((prev: any) =>
      prev.map((crypto: any) =>
        crypto.abbr === abbr
          ? { ...crypto, newRate: isNumber ? Number(value) : "" }
          : crypto
      )
    );
  };

  // Render row for each cryptocurrency
  const renderRow = (crypto: any) => {
    const { name, abbr, image, oldRate, newRate, adding, loading } = crypto;
    return (
      <tr className="border-b border-[#13181B]" key={abbr}>
        <td className="p-4 w-1/4 text-left text-[16px] pl-8">
          <div className="flex items-center gap-2">
            <img src={image} alt={name} className="w-[24px] h-[24px]" />
            {name}
          </div>
        </td>
        <td className="p-4 w-1/4 text-left text-[16px]">
          <div className="bg-[#1D1D1D] px-2 w-[80px] h-[34px] text-[15px] text-white flex justify-center items-center">
            {abbr}
          </div>
        </td>
        <td className="p-3 w-1/4 text-start text-[16px]">{oldRate || 0}</td>
        <td className="p-3 w-1/4 text-left">
          {adding ? (
            <div className="flex items-center gap-2 justify-start">
              <input
                type="text"
                value={newRate}
                onChange={(e) => handleRateChange(abbr, e.target.value)}
                className="w-[100px] border outline-none bg-transparent rounded-lg border-gray-600 h-[32px] text-[14px] px-2"
              />
              <button
                onClick={() => uploadRate(abbr)}
                disabled={!newRate}
                className={`w-[100px] h-[32px] rounded-lg flex justify-center items-center ${
                  newRate
                    ? "bg-secondary_button text-white"
                    : "bg-gray-600 text-gray-300"
                }`}
              >
                {loading ? (
                  <TailSpin color="#FFFFFF" height={25} width={25} />
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          ) : (
            <span
              className="cursor-pointer text-[16px] text-left"
              onClick={() =>
                setCryptoData((prev) =>
                  prev.map((crypto) =>
                    crypto.abbr === abbr ? { ...crypto, adding: true } : crypto
                  )
                )
              }
            >
              Add
            </span>
          )}
        </td>
      </tr>
    );
  };

  return (
    <table className="w-full font-manrope px-8">
      <thead className="bg-[#1C2227] h-[53px] ">
        <tr className="">
          <th className="text-[16px] text-[#617889] text-left pl-8  w-1/4">
            Coin
          </th>
          <th className="text-[16px] text-[#617889] text-left  w-1/4">
            Network
          </th>
          <th className="text-[16px] text-[#617889] text-left  w-1/4">
            Current Rate
          </th>
          <th className="text-[16px] text-[#617889] text-left  w-1/4">
            New Rate
          </th>
        </tr>
      </thead>
      <tbody className="bg-[#0D0D0D]">{cryptoData.map(renderRow)}</tbody>
    </table>
  );
};

export default CryptoRate;
