import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { STAGING_LINK, PROD_LINK, API_URL } from "../../constant";
import { IoArrowUp } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import WalletGraph from "./WalletGraph";
import AssetsPie from "./AssetsPie";
// import { BiExport } from "react-icons/bi";
import { useAuth } from "../../context/auth-context";
import axios from "axios";
import Receive from "./crypto/Receive";
import Withdraw from "./crypto/Withdraw";
// import Wallets from "./Wallets";
import DepositFiat from "./fiat/DepositFiat";
import WithdrawFiat from "./fiat/WithdrawFiat";
import { TailSpin } from "react-loader-spinner";
import ConfirmWithdrawal from "./fiat/ConfirmWithdrawal";
import SuccessFiat from "./fiat/SuccessFiat";
import WalletActivities from "./WalletActivities";
import { CSVLink } from "react-csv";
import { BiExport } from "react-icons/bi";
import { FaArrowCircleRight, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import CreatePayroll from "./payroll/CreatePayroll";
import ConfirmCreatePayroll from "./payroll/ConfirmCreatePayroll";
import PayrollSuccess from "./payroll/PayrollSuccess";
import PayrollActivities from "./PayrollActivities";

const Wallet = () => {
  const {
    withdrawModal,
    setWithdrawModal,
    token,
    isOpened,
    isNotified,
    logout,
  } = useAuth();
  const [receiveModal, setReceiveModal] = useState<boolean>(false);
  const [btcValue, setBtcValue] = useState<number | any>();
  const [usdtValue, setUsdtValue] = useState<number | null>(null);
  const [nairaBalance, setNairaBalance] = useState<number | any>();
  const [depositFiatModal, setDepositFiatModal] = useState<boolean>(false);
  const [withdrawFiatModal, setWithdrawFiatModal] = useState<boolean>(false);
  const [autoWithdraw, setAutoWithdraw] = useState<string>("");
  const [payLoader, setPayLoader] = useState<boolean>(false);
  const [refetchCounter, setRefetchCounter] = useState<number>(0);
  const [confirmFiatModal, setConfirmFiatModal] = useState<boolean>(false);
  const [successFiatModal, setSuccessFiatModal] = useState<boolean>(false);
  const [bankCode, setBankCode] = useState<string>("");
  const [active, setActive] = useState<boolean>(true);
  const [bankNumber, setBankNumber] = useState<string>("");
  const [visibleB, setVisibleB] = useState<boolean>(false);
  const [newActivities, setNewActivities] = useState<any[]>([]);
  const [withdrawAmount, setWithdrawAmount] = useState<number | string>("");
  const [transacion, setTransaction] = useState<boolean>(false);
  const [openPayroll, setOpenPayroll] = useState<boolean>(false);
  const [openPayrollConfirm, setOpenPayrollConfirm] = useState<boolean>(false);
  const [openPayrollSuccess, setOpenPayrollSuccess] = useState<boolean>(false);
  const [selectedStaffs, setSelectedStaffs] = useState<string[]>([]); // Array of selected staff IDs

  useEffect(() => {
    setPayLoader(true);
    setTimeout(() => {
      axios
        .get(`${API_URL}api/settings`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r: any) => {
          setAutoWithdraw(r.data.data.autoWithdrawalStatus);

          setPayLoader(false);
        })
        .catch((e: any) => {
          setPayLoader(false);
          console.log(e.response.data.message);
        });
    }, 1500);
  }, [token, refetchCounter]);

  useEffect(() => {
    axios
      .get(`${API_URL}api/wallets/admin?currency=all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        const newNairaBalance = r.data.data.hizo.balance;
        setNairaBalance(newNairaBalance);
        const walletBalance = r.data.data.quidax;
        const btcObject = walletBalance.find(
          (obj: any) => obj.name === "Bitcoin"
        );
        const usdtObject = walletBalance.find(
          (obj: any) => obj.name === "USDT Tether"
        );
        if (btcObject) {
          setBtcValue(btcObject.balance);
        }
        if (usdtObject) {
          const balance = parseFloat(usdtObject.balance);
          setUsdtValue(!isNaN(balance) ? balance : null);
        }
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
        if (
          e.response.status === 401 &&
          e.response.data.message === "Inavlid token. Please login again!"
        ) {
          logout();
        }
      });
  }, [token, logout]);

  const handleChange = () => {
    if (autoWithdraw === "inactive") {
      if (!payLoader) {
        setPayLoader(true);
        axios
          .patch(
            `${API_URL}api/settings/autowithdrawal-status`,
            {
              status: "active",
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((r: any) => {
            // console.log(r.data);
            setRefetchCounter((prev) => prev + 1);
            setPayLoader(false);
            // .location.reload();
          })
          .catch((e: any) => {
            console.log(e.response.data.message);
          });
      }
    } else {
      if (!payLoader) {
        setPayLoader(true);
        axios
          .patch(
            `${API_URL}api/settings/autowithdrawal-status`,
            {
              status: "inactive",
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((r: any) => {
            setRefetchCounter((prev) => prev + 1);
            setPayLoader(false);
            // window.location.reload();
          })
          .catch((e: any) => {
            console.log(e.response.data.message);
          });
      }
    }
  };

  const data = newActivities.map((transaction) => ({
    name: `${transaction.userDetails.profile.firstName} ${transaction.userDetails.profile.lastName}`,
    email: `${transaction.userDetails.email.address}`,
    dateCreated: `${(() => {
      const dateString = transaction.createdAt;
      const date = new Date(dateString);

      const day = date.getDate();
      const ordinalSuffix = (day: number) => {
        switch (day) {
          case 1:
          case 21:
          case 31:
            return "st";
          case 2:
          case 22:
            return "nd";
          case 3:
          case 23:
            return "rd";
          default:
            return "th";
        }
      };

      const formattedDate = `${date.toLocaleString("default", {
        month: "short",
      })} ${day}${ordinalSuffix(
        day
      )}, ${date.getFullYear()}, ${date.toLocaleString("default", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })}`;

      return formattedDate;
    })()}`,
    amountTraded: `${
      transaction.transactionType === "deex-asset-swaps"
        ? transaction.to.amount.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : transaction.transactionType === "deex-deposits"
        ? transaction.amount
        : transaction.transactionType === "payouts"
        ? transaction.asset.amountInUSD.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : transaction.transactionType === "deex-fiat-deposits"
        ? "----"
        : transaction.usdtAmount
    } ${
      transaction.transactionType === "deex-deposits" &&
      transaction.currency === "btc"
        ? "BTC"
        : "USD"
    }`,
    amountInNGN: `${
      transaction.transactionType === "payouts"
        ? transaction.payoutAmount.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : transaction.transactionType === "deex-assets-swaps"
        ? "----"
        : "----"
    }`,
    providerName: `${
      transaction.transactionType === "payouts" ||
      transaction.transactionType === "deex-fiat-deposits"
        ? "Hizo"
        : transaction.transactionType === "deex-deposits"
        ? transaction.provider.name
        : "Quidax"
    }`,
    transactionType: `${
      transaction.transactionType === "deex-asset-swaps"
        ? "Autoswap"
        : transaction.transactionType === "payouts" &&
          transaction.transaction.type === "DEEX-POINT"
        ? "Reward"
        : transaction.transactionType === "payouts"
        ? "Payout"
        : "Deposit"
    }`,
    transactionID: `${transaction._id}`,
    transactionStatus: `${
      transaction.transactionType === "payouts"
        ? transaction.status
        : "Completed"
    }`,
  }));

  return (
    <div className="w-full">
      <div className="w-full h-[31px] bg-[#1B1B1B] flex justify-center items-center">
        {API_URL?.includes("staging") ? (
          <h4 className="font-medium text-[#AEBCC6] text-[12px]">
            You are in Staging:{" "}
            <span>
              <a href={PROD_LINK} className="text-[#03C668]">
                Switch to Prod
              </a>
            </span>
          </h4>
        ) : (
          <h4 className="font-medium text-[#AEBCC6] text-[12px]">
            You are in PROD:{" "}
            <span>
              <a href={STAGING_LINK} className="text-[#DF4E4E]">
                Switch to Staging
              </a>
            </span>
          </h4>
        )}
      </div>
      <div className="w-full lgss:flex lgss:flex-row  h-screen">
        <Sidebar />
        <div className="w-full lgss:w-10/12 h-full overflow-auto bg-[#13181B]">
          <Navbar />

          <div className="w-full px-5 pt-8 mds:px-12 font-manrope lgss:px-10 pb-24 bg-[#13181B]">
            <div className="w-full flex justify-between gap-2 items-center ">
              <h4 className="text-white font-sora font-semibold text-[10px]">
                WALLETS
              </h4>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setVisibleB((prev) => !prev)}
                  className="flex items-center uppercase mt-1 gap-2 font-sora text-[#869AA9] font-semibold text-[10px]"
                >
                  {visibleB ? (
                    <FaRegEye className="w-[14px] h-[14px] text-[#4E606E]" />
                  ) : (
                    <FaRegEyeSlash className="w-[14px] h-[14px] text-[#4E606E]" />
                  )}
                  {visibleB ? "HIDE" : "SHOW"} Balance
                </button>
                <h4 className="mt-1 text-[#3A4852]">|</h4>
                <CSVLink
                  data={data}
                  filename="Wallet activities"
                  className="flex gap-1 items-center"
                >
                  <BiExport className="text-[white]" />
                  <h4 className="uppercase font-sora font-semibold mt-1 text-[#869AA9] text-[10px]">
                    Export
                  </h4>
                </CSVLink>
              </div>
            </div>
            <div className="w-full flex flex-col lgss:flex-row gap-4 justify-center items-center">
              <div className="w-full lgss:w-[67%]">
                <div className="mt-4 bg-[#1C2227] p-5">
                  <h4 className="text-[#E7EBEE]  font-bold text-[11px]">
                    Trade Volume
                  </h4>
                  <div
                    className={
                      receiveModal || withdrawModal
                        ? "w-full h-[500px]"
                        : "w-full "
                    }
                  >
                    {!receiveModal &&
                    !withdrawModal &&
                    !isOpened &&
                    !openPayroll &&
                    !openPayrollConfirm &&
                    !openPayrollSuccess &&
                    !isNotified ? (
                      <WalletGraph />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="w-full lgss:w-[33%] bg-[#1C2227] h-[430px] mt-4 ">
                <h4 className="text-[#E7EBEE] p-4  font-bold text-[11px]">
                  Assets
                </h4>
                <div className="w-full h-[80%] flex flex-col justify-center items-center">
                  {!receiveModal &&
                  !withdrawModal &&
                  !isOpened &&
                  !isNotified ? (
                    <AssetsPie />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="w-full grid grid-cols-1 mt-8 lgss:grid-cols-2 gap-4">
              <div className="w-full bg-[#1C2227] rounded-sm  p-5">
                <h4 className="text-[#E7EBEE]  font-bold text-[11px]">
                  Total Crypto
                </h4>
                <h4 className="font-sora font-bold text-[24px] text-[#BEE1FB] mt-2">
                  {visibleB
                    ? `$${
                        usdtValue !== null
                          ? usdtValue.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "0.00"
                      }`
                    : `********`}
                </h4>
                <div className="w-full lgss:w-2/3 mt-6 gap-2 flex justify-start items-center">
                  <button
                    onClick={() => setReceiveModal((prev) => !prev)}
                    className="w-1/2 md:w-[45%] gap-2 flex justify-center items-center py-3 text-white bg-secondary_button font-bold text-[15px] rounded-lg"
                  >
                    <div className="w-[24px] h-[24px] flex justify-center items-center text-[16px] text-white bg-[#095B97] rounded-lg">
                      <MdAdd />
                    </div>
                    <h4>Fund Wallet</h4>
                  </button>
                  <button
                    onClick={() => setWithdrawModal((prev: any) => !prev)}
                    className="w-1/2 md:w-[45%] gap-2 flex justify-center items-center py-3 px-3 text-[#1C2227] bg-[#F7F8F9] font-bold text-[15px] rounded-lg"
                  >
                    <div className="w-[24px] h-[24px] flex justify-center items-center text-[16px] text-[#1C2227] bg-[#E7EBEE] rounded-lg">
                      <IoArrowUp />
                    </div>
                    <h4>Withdraw</h4>
                  </button>
                </div>
              </div>
              <div className="w-full bg-[#1C2227] rounded-sm  p-5">
                <h4 className="text-[#E7EBEE]  font-bold text-[11px]">
                  Total Fiat
                </h4>
                <h4 className="font-sora font-bold text-[24px] text-[#BEE1FB] mt-2">
                  {visibleB
                    ? `₦${
                        nairaBalance
                          ? nairaBalance.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : 0.0
                      }`
                    : `********`}
                </h4>
                <div className="w-full lgss:w-2/3 mt-6 gap-2 flex justify-start items-center">
                  <button
                    onClick={() => setDepositFiatModal((prev) => !prev)}
                    className="w-1/2 md:w-[45%] gap-2 flex justify-center items-center py-3 text-white bg-secondary_button font-bold text-[15px] rounded-lg"
                  >
                    <div className="w-[24px] h-[24px] flex justify-center items-center text-[16px] text-white bg-[#095B97] rounded-lg">
                      <MdAdd />
                    </div>
                    <h4>Fund Wallet</h4>
                  </button>
                  <button
                    onClick={() => setWithdrawFiatModal((prev: any) => !prev)}
                    className="w-1/2 md:w-[45%] gap-2 flex justify-center items-center py-3 px-3 text-[#1C2227] bg-[#F7F8F9] font-bold text-[15px] rounded-lg"
                  >
                    <div className="w-[24px] h-[24px] flex justify-center items-center text-[16px] text-[#1C2227] bg-[#E7EBEE] rounded-lg">
                      <IoArrowUp />
                    </div>
                    <h4>Withdraw</h4>
                  </button>
                </div>
              </div>
            </div>

            <div className="w-full flex justify-between items-center mt-28 mb-6">
              <h4 className="text-white font-sora font-semibold text-[12px]">
                Activity
              </h4>
              <div className="flex gap-4 items-center">
                <div className="flex items-center gap-2">
                  <div
                    onClick={() => setActive(true)}
                    className={`flex w-6  cursor-pointer h-3   rounded-full transition-all duration-500 ${
                      active ? "bg-secondary_button" : "bg-gray-600"
                    }`}
                  >
                    <span
                      className={`h-3 w-3   rounded-full transition-all duration-500 bg-gray-100 ${
                        active ? "ml-3" : ""
                      }`}
                    ></span>
                  </div>
                  <h4 className="uppercase font-sora font-semibold text-[#869AA9] text-[10px]">
                    Auto swap :{" "}
                    <span
                      className={active ? "text-[#359943]" : "text-[#617889]"}
                    >
                      {active ? "on" : "off"}
                    </span>
                  </h4>
                </div>
                <h4 className="text-[#3A4852]">|</h4>
                <div className="flex items-center gap-2">
                  {payLoader ? (
                    <TailSpin color="#0B75C2" height={20} width={20} />
                  ) : (
                    <div
                      onClick={handleChange}
                      className={`flex w-8  cursor-pointer h-4   rounded-full transition-all duration-500 ${
                        autoWithdraw === "active"
                          ? "bg-secondary_button"
                          : "bg-gray-600"
                      }`}
                    >
                      <span
                        className={`h-4 w-4   rounded-full transition-all duration-500 bg-gray-100 ${
                          autoWithdraw === "active" ? "ml-4" : ""
                        }`}
                      ></span>
                    </div>
                  )}
                  <h4 className="uppercase font-sora font-semibold text-[#869AA9] text-[10px]">
                    Auto Withdrawal:{" "}
                    <span
                      className={
                        autoWithdraw === "active" ? "text-[#359943]" : ""
                      }
                    >
                      {autoWithdraw === "active" ? " ON" : " OFF"}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="bg-[#0D0D0D] w-full flex justify-between items-center pr-12 h-[67px] mt-4">
              <div className="w-1/2 h-full flex justify-start items-center">
                <button
                  onClick={() => {
                    setTransaction(false);
                  }}
                  className={
                    !transacion
                      ? "border-secondary_button h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                      : " h-full px-6 text-[14px]  text-[#C6D0D7]  "
                  }
                >
                  All
                </button>
                <button
                  onClick={() => setTransaction(true)}
                  className={
                    transacion
                      ? "border-secondary_button h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                      : " h-full px-6 text-[14px]  text-[#C6D0D7]  "
                  }
                >
                  Payroll
                </button>
              </div>
              {transacion && (
                <button
                  onClick={() => setOpenPayroll(true)}
                  className="px-4 py-2 rounded-xl gap-2 bg-secondary_button text-white text-[15px] justify-center items-center flex"
                >
                  <h4>Create Payroll</h4>
                  <FaArrowCircleRight className="text-[11px]" />
                </button>
              )}
            </div>
            {transacion ? (
              <PayrollActivities />
            ) : (
              <WalletActivities setNewActivities={setNewActivities} />
            )}
          </div>
        </div>
      </div>
      {withdrawModal ? (
        <Withdraw btcValue={btcValue} usdtValue={usdtValue} />
      ) : null}
      {receiveModal && <Receive setRecieveModal={setReceiveModal} />}
      {depositFiatModal && (
        <DepositFiat setDepositFiatModal={setDepositFiatModal} />
      )}
      {withdrawFiatModal && (
        <WithdrawFiat
          setWithdrawFiatModal={setWithdrawFiatModal}
          setConfirmFiatModal={setConfirmFiatModal}
          withdrawAmount={withdrawAmount}
          setWithdrawAmount={setWithdrawAmount}
          bankCode={bankCode}
          setBankCode={setBankCode}
          bankNumber={bankNumber}
          setBankNumber={setBankNumber}
        />
      )}
      {confirmFiatModal && (
        <ConfirmWithdrawal
          setConfirmFiatModal={setConfirmFiatModal}
          withdrawAmount={withdrawAmount}
          bankCode={bankCode}
          bankNumber={bankNumber}
          setSuccessFiatModal={setSuccessFiatModal}
        />
      )}
      {successFiatModal && (
        <SuccessFiat setSuccessFiatModal={setSuccessFiatModal} />
      )}
      {openPayroll && (
        <CreatePayroll
          setOpenPayroll={setOpenPayroll}
          setOpenPayrollConfirm={setOpenPayrollConfirm}
          setSelectedStaffs={setSelectedStaffs}
          selectedStaffs={selectedStaffs}
        />
      )}
      {openPayrollConfirm && (
        <ConfirmCreatePayroll
          setOpenPayroll={setOpenPayroll}
          setOpenPayrollConfirm={setOpenPayrollConfirm}
          setOpenPayrollSuccess={setOpenPayrollSuccess}
          selectedStaffs={selectedStaffs}
        />
      )}
      {openPayrollSuccess && (
        <PayrollSuccess setOpenPayrollSuccess={setOpenPayrollSuccess} />
      )}
    </div>
  );
};

export default Wallet;
