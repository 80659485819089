import React, { useEffect, useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { useAuth } from "../../../context/auth-context";
import axios from "axios";
import { API_URL, options } from "../../../constant";
import { TailSpin } from "react-loader-spinner";
import { BiErrorCircle } from "react-icons/bi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Select from "react-select";

const AddNewMember = ({ setOpenPayroll, setRefetch }: any) => {
  const [fullname, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [role, setRole] = useState<any>("");
  //bank

  const [bankName, setBankName] = useState<any[]>([]);
  const [bankCode, setBankCode] = useState<string>("");
  const [bankNumber, setBankNumber] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const { token } = useAuth();
  const [userName, setUserName] = useState<string>("");
  const [bankLoading, setBankLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [displayAmount, setDisplayAmount] = useState<string>(""); // Stores formatted amount
  const isNumber = (str: string) => /^\d*\.?\d*$/.test(str); // Allows numbers and decimals
  const handleAmtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/,/g, ""); // Remove commas for parsing
    if (isNumber(value)) {
      setAmount(value); // Store raw amount
      setDisplayAmount(value); // Temporarily show raw input while typing
    }
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      if (amount) {
        const formattedValue = Number(amount).toLocaleString("en-US");
        setDisplayAmount(formattedValue); // Set formatted value after debounce
      }
    }, 500); // 500ms debounce time

    return () => clearTimeout(handler); // Cleanup on re-render
  }, [amount]);

  const validateAccount = (
    bankNumber: string,
    email: string,
    fullname: string,
    salary: string,
    role: any
  ) => {
    if (!role?.label) {
      setError("kindly provide the staff role");
      setTimeout(() => {
        setError("");
      }, 2000);
      return false;
    }
    if (!salary) {
      setError("kindly provide the staff salary");
      setTimeout(() => {
        setError("");
      }, 2000);
      return false;
    }
    if (!fullname) {
      setError("kindly provide the staff fullname");
      setTimeout(() => {
        setError("");
      }, 2000);
      return false;
    }
    if (!email) {
      setError("kindly provide the staff email");
      setTimeout(() => {
        setError("");
      }, 2000);
      return false;
    }
    if (!bankNumber) {
      setError("kindly provide your account Number");
      setTimeout(() => {
        setError("");
      }, 2000);
      return false;
    }
    if (bankNumber.length !== 10) {
      setError("Account number must be 10 digits");
      setTimeout(() => {
        setError("");
      }, 2000);
      return false;
    }

    setError("");
    return true;
  };

  useEffect(() => {
    axios
      .get(`${API_URL}api/banks`)
      .then((r: any) => {
        setBankName(r.data.data);
      })
      .catch((e: any) => {
        setError(e.response.data.message);
        setLoading(false);
        setTimeout(() => {
          setError("");
        }, 3000);
        console.log(e.response.data.message);
      });
  }, []);
  const handleChange = (event: any) => {
    const selectedBank = bankName.find(
      (bank) => bank.name === event.target.value
    );
    if (selectedBank) {
      setBankCode(selectedBank.code);
    }
  };

  useEffect(() => {
    if (bankNumber.length === 10 && bankCode !== "") {
      setBankLoading(true);
      axios
        .get(
          `${API_URL}api/banks/account-name?accountNumber=${bankNumber}&bankCode=${bankCode}`
        )
        .then((r: any) => {
          setBankLoading(false);
          setUserName(r.data.data.account_name);
        })
        .catch((e: any) => {
          setUserName("");
          setBankLoading(false);
          setLoading(false);
          setError(e.response.data.message);
          setTimeout(() => {
            setError("");
          }, 6000);
          console.log(e.response.data.message);
        });
    }
    setUserName("");
  }, [bankNumber, bankCode]);
  useEffect(() => {
    if (!userName) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [userName]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateAccount(bankNumber, email, fullname, amount, role)) {
      return;
    }

    if (!loading) {
      setLoading(true);
      const requestData = {
        fullName: fullname?.trim(),
        email: email?.trim(),
        bankDetails: {
          accountNumber: bankNumber,
          bankCode: bankCode,
          accountName: userName,
        },
        salary: amount,
        role: role?.label,
      };
      axios
        .post(`${API_URL}api/payroll/staff`, requestData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setSuccess("Staff Details Added");
          setLoading(false);
          setRefetch((prev: any) => prev + 1);
          setTimeout(() => {
            setSuccess("");
            setOpenPayroll(false);
          }, 2000);
        })
        .catch((e) => {
          setError(e.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setError("");
          }, 2000);

          console.log(e.response.data.message);
        });
    }
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: "12px",
      border: "1px solid #141414",
      backgroundColor: "#1C2227",
      padding: "0 6px",
      height: "46px",
      boxShadow: "none",
      fontSize: "14px",
      color: "#A0A9AD",
      margin: "8px 0px",
      minHeight: "46px",
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: "8px",
      boxShadow: "none",
      marginTop: "0",
      padding: "0",
      backgroundColor: "#1C2227", // Keeps the same background color
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: "0",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#1C2227" : "#1C2227",
      color: "#A0A9AD",
      padding: "10px 16px",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#AEBCC6",
        color: "#1C2227",
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#A0AEC0",
      fontSize: "14px",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#A0A9AD",
    }),
    input: (provided: any) => ({
      ...provided,
      color: "#A0A9AD", // Ensures typed text matches the dropdown text color
      backgroundColor: "transparent", // Keeps the input background transparent
    }),
    noOptionsMessage: (provided: any) => ({
      ...provided,
      backgroundColor: "#1C2227", // Keeps the background consistent
      color: "#A0A9AD", // Matches the text color
      padding: "10px", // Adds padding for better UI
    }),
  };

  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-80 backdrop-blur-sm">
      <div className="w-11/12 lgss:w-2/5 transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F] ">
        <div className="flex  pt-4 justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Add new member
          </h4>
          <FaTimesCircle
            onClick={() => setOpenPayroll((prev: any) => !prev)}
            className="text-white text-[32px] cursor-pointer"
          />
        </div>
        {success && (
          <div className="bg-green-500 mt-2  text-white w-full text-[16px] rounded-xl justify-start items-center gap-4 flex h-[60px] px-6 font-bold">
            <AiOutlineCheckCircle className="text-3xl text-white" />
            {success}
          </div>
        )}
        <div className="w-full mt-8">
          <form className="w-full" onSubmit={handleSubmit}>
            <div className="">
              <label className=" font-bold text-white">Full Name</label>
              <input
                placeholder="Full name"
                name="fullname"
                type="text"
                value={fullname}
                onChange={(e) => setFullName(e.target.value)}
                className="h-[48px] w-full mt-2  px-[16px] text-[14px]   font-manrope border rounded-xl border-[#141414] outline-none bg-[#1C2227]  text-[#A0A9AD] transition duration-200"
              />
            </div>
            <div className="mt-4">
              <label className=" font-bold text-white">Email address</label>
              <input
                placeholder="Enter valid email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                className="h-[48px] w-full mt-2  px-[16px] text-[14px]   font-manrope border rounded-xl border-[#141414] outline-none bg-[#1C2227]  text-[#A0A9AD] transition duration-200"
              />
            </div>
            <div className="mt-4">
              <label className=" font-bold text-white">Role</label>

              <Select
                options={options}
                styles={customStyles}
                onChange={(option: any) => setRole(option)}
              />
            </div>

            <div className="mt-4">
              <label className=" font-bold text-white">Salary</label>
              <div className="w-full flex justify-start mt-2 items-center gap-1  px-4 text-[#A0A9AD] bg-[#1C2227] border-[#141414]  h-[48px]   font-manrope border rounded-xl  outline-none    transition duration-200">
                <h4>₦</h4>
                <input
                  type="text" // Changed to "text" to allow formatted input
                  placeholder="eg: 50,000 NGN"
                  value={displayAmount} // Show formatted amount or raw input
                  onChange={handleAmtChange}
                  className="w-full outline-none py-3 text-[14px] bg-transparent"
                />
              </div>
            </div>
            <div className="w-full flex mt-4 justify-center gap-4 items-start">
              <div className="w-2/5">
                <label className=" font-bold text-white">Bank</label>
                <select
                  onChange={handleChange}
                  className="h-[48px] w-full mt-2  px-[16px] text-[14px]  font-manrope border rounded-xl border-[#141414] outline-none bg-[#1C2227]  text-[#A0A9AD] transition duration-200"
                >
                  <option>Select your bank</option>
                  {bankName.map((bank, index) => (
                    <option key={index} className="p-4">
                      {bank.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-3/5">
                <label className=" font-bold text-white">Account number</label>
                <input
                  type="number"
                  placeholder="Enter account number"
                  value={bankNumber}
                  onChange={(e) => setBankNumber(e.target.value)}
                  className="h-[48px] w-full mt-2  px-[16px] text-[14px]  font-manrope border rounded-xl spin-button-none border-[#141414] outline-none bg-[#1C2227]  text-[#A0A9AD] transition duration-200"
                />
                <h4 className="text-[14px] mt-2 font-bold text-[#d39c54]">
                  {bankLoading ? (
                    <TailSpin color="#FFFFFF" height={20} width={30} />
                  ) : (
                    `${userName}`
                  )}
                </h4>
              </div>
            </div>
            {error && (
              <div className="bg-red-600 w-full  text-white text-[12px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-bold mt-4">
                <BiErrorCircle className="text-2xl" />
                {error}
              </div>
            )}
            <button
              disabled={disabled}
              type="submit"
              className={
                disabled
                  ? "w-full h-[50px] mt-6 rounded-xl bg-gray-400 text-[16px] text-gray-200 font-medium "
                  : "w-full h-[50px] mt-6 rounded-xl bg-secondary_button flex justify-center items-center text-[16px] text-white font-medium "
              }
            >
              {loading ? (
                <TailSpin color="#FFFFFF" height={30} width={30} />
              ) : (
                "Add member"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewMember;
