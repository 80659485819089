import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useAuth } from "../../context/auth-context";
import Security from "./Security";
import Admin from "./Admin";
import { API_URL, PROD_LINK, STAGING_LINK } from "../../constant";
import axios from "axios";
import Fees from "./fees/Fees";
import CrytoFeeModal from "./fees/CrytoFeeModal";
import CryptoReview from "./fees/CryptoReview";
import GiftcardFeeModal from "./fees/GiftcardFeeModal";
import GiftcardReview from "./fees/GiftcardReview";
import WalletFee from "./fees/WalletFee";
import WalletReview from "./fees/WalletReview";
import PayrollList from "./payroll/PayrollList";
import AddNewMember from "./payroll/AddNewMember";
import StaffDetails from "./payroll/StaffDetails";

const Settings = () => {
  const { activeSetting, setActiveSetting, token, logout } = useAuth();
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [openPayroll, setOpenPayroll] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<number>(1);
  const [staffDeet, setStaffDeet] = useState<any[]>([]);
  const [openStaffModal, setOpenStaffModal] = useState<boolean>(false);
  const [cryptoFeeModal, setCryptoFeeModal] = useState<boolean>(false);
  const [giftcardFeeModal, setGiftcardFeeModal] = useState<boolean>(false);
  const [walletFeeModal, setWalletFeeModal] = useState<boolean>(false);
  const [cryptoReviewModal, setCryptoReviewModal] = useState<boolean>(false);
  const [walletReviewModal, setWalletReviewModal] = useState<boolean>(false);
  const [giftcardReviewModal, setGiftcardReviewModal] =
    useState<boolean>(false);
  const [sellType, setSellType] = useState<string>("PERCENTILE");
  const [sellGiftcardType, setSellGiftcardType] =
    useState<string>("PERCENTILE");
  const [sellInternalType, setSellInternalType] =
    useState<string>("PERCENTILE");
  const [sellexternalType, setSellexternalType] =
    useState<string>("PERCENTILE");
  const [sellFee, setSellFee] = useState<number | "">("");
  const [sellGiftcardFee, setSellGiftcardFee] = useState<number | "">("");
  const [sellInternalFee, setSellInternalFee] = useState<number | "">("");
  const [sellexternalFee, setSellexternalFee] = useState<number | "">("");
  const [fiatFee, setFiatFee] = useState<number | "">("");
  const [previousSellValue, setPreviousSellValue] = useState<any | number>();
  const [previousSellType, setPreviousSellType] = useState<string>("");
  const [previousSellInternalType, setPreviousSellInternalType] =
    useState<string>("");
  const [previousSellExternalType, setPreviousSellExternalType] =
    useState<string>("");
  const [previousSellInternalValue, setPreviousSellInternalValue] = useState<
    any | number
  >();
  const [previousSellExternalValue, setPreviousSellExternalValue] = useState<
    any | number
  >();
  const [cryptoRefetch, setCryptoRefetch] = useState<number>(0);
  const [previousGiftcardFee, setPreviousGiftcardFee] = useState<
    any | number
  >();
  const [previousGiftcardType, setPreviousGiftcardType] = useState<string>();

  useEffect(() => {
    axios
      .get(`${API_URL}api/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        if (r.data.data.user.roles.includes("super-admin")) {
          setIsSuperAdmin(true);
        }
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
        if (
          e.response.status === 401 &&
          e.response.data.message === "Inavlid token. Please login again!"
        ) {
          logout();
        }
      });
  }, [token, logout]);
  return (
    <div className="w-full">
      <div className="w-full h-[31px] bg-[#1B1B1B] flex justify-center items-center">
        {API_URL?.includes("staging") ? (
          <h4 className="font-medium text-[#AEBCC6] text-[12px]">
            You are in Staging:{" "}
            <span>
              <a href={PROD_LINK} className="text-[#03C668]">
                Switch to Prod
              </a>
            </span>
          </h4>
        ) : (
          <h4 className="font-medium text-[#AEBCC6] text-[12px]">
            You are in PROD:{" "}
            <span>
              <a href={STAGING_LINK} className="text-[#DF4E4E]">
                Switch to Staging
              </a>
            </span>
          </h4>
        )}
      </div>
      <div className="w-full lgss:flex lgss:flex-row  h-screen">
        <Sidebar />
        <div className="w-full lgss:w-10/12 h-full overflow-auto bg-[#13181B]">
          <Navbar />

          <div className="w-full px-5 pt-8 mds:px-12 font-manrope lgss:px-10 pb-24 bg-[#13181B]">
            <div className="w-full flex justify-between items-center">
              <div className="flex justify-start items-center ">
                <h4
                  onClick={() => setActiveSetting(1)}
                  className={
                    activeSetting === 1
                      ? "cursor-pointer focus:outline-none focus:shadow-none transition ease-in duration-400 outline-none text-[18px] font-semibold border-b-2 border-secondary_button p-6  text-secondary_button"
                      : "cursor-pointer focus:outline-none focus:shadow-none transition ease-in duration-400 outline-none text-[16px] font-semibold text-[#C6D0D7] p-6"
                  }
                >
                  Security
                </h4>
                <h4
                  onClick={() => setActiveSetting(3)}
                  className={
                    activeSetting === 3
                      ? "cursor-pointer focus:outline-none focus:shadow-none transition ease-in duration-400 outline-none text-[18px] font-semibold border-b-2 border-secondary_button p-6  text-secondary_button"
                      : "cursor-pointer focus:outline-none focus:shadow-none transition ease-in duration-400 outline-none text-[16px] font-semibold text-[#C6D0D7] p-6"
                  }
                >
                  Fees
                </h4>
                <h4
                  onClick={() => setActiveSetting(4)}
                  className={
                    activeSetting === 4
                      ? "cursor-pointer focus:outline-none focus:shadow-none transition ease-in duration-400 outline-none text-[18px] font-semibold border-b-2 border-secondary_button p-6  text-secondary_button"
                      : "cursor-pointer focus:outline-none focus:shadow-none transition ease-in duration-400 outline-none text-[16px] font-semibold text-[#C6D0D7] p-6"
                  }
                >
                  Payroll
                </h4>
                {isSuperAdmin && (
                  <h4
                    onClick={() => setActiveSetting(2)}
                    className={
                      activeSetting === 2
                        ? "cursor-pointer focus:outline-none focus:shadow-none transition ease-in duration-400 outline-none text-[18px] font-semibold border-b-2 border-secondary_button p-6  text-secondary_button"
                        : "cursor-pointer focus:outline-none focus:shadow-none transition ease-in duration-400 outline-none text-[16px] font-semibold text-[#C6D0D7] p-6"
                    }
                  >
                    Admin
                  </h4>
                )}
              </div>
              {activeSetting === 4 && (
                <button
                  onClick={() => setOpenPayroll(true)}
                  className="p-3 rounded-lg bg-secondary_button text-[14px] text-white font-medium "
                >
                  Add member
                </button>
              )}
            </div>
            <div className="w-full flex bg-[#3A4852] items-center justify-start lgss:justify-start h-[1px]" />
            <div className="w-full  mt-12">
              <div
                className={
                  activeSetting === 2
                    ? "rounded-2xl bg-[#0D0D0D] text-white"
                    : "rounded-2xl  bg-[#0D0D0D] text-white"
                }
              >
                {activeSetting === 1 ? (
                  <Security />
                ) : activeSetting === 2 && isSuperAdmin ? (
                  <Admin />
                ) : activeSetting === 3 ? (
                  <Fees
                    setCryptoFeeModal={setCryptoFeeModal}
                    setGiftcardFeeModal={setGiftcardFeeModal}
                    setWalletFeeModal={setWalletFeeModal}
                    previousSellValue={previousSellValue}
                    setPreviousSellValue={setPreviousSellValue}
                    previousSellType={previousSellType}
                    setPreviousSellType={setPreviousSellType}
                    previousSellInternalType={previousSellInternalType}
                    setPreviousSellInternalType={setPreviousSellInternalType}
                    previousSellExternalType={previousSellExternalType}
                    setPreviousSellExternalType={setPreviousSellExternalType}
                    previousSellInternalValue={previousSellInternalValue}
                    setPreviousSellInternalValue={setPreviousSellInternalValue}
                    previousSellExternalValue={previousSellExternalValue}
                    setPreviousSellExternalValue={setPreviousSellExternalValue}
                    cryptoRefetch={cryptoRefetch}
                    setPreviousGiftcardFee={setPreviousGiftcardFee}
                    setPreviousGiftcardType={setPreviousGiftcardType}
                    previousGiftcardFee={previousGiftcardFee}
                    previousGiftcardType={previousGiftcardType}
                  />
                ) : activeSetting === 4 ? (
                  <PayrollList
                    setOpenStaffModal={setOpenStaffModal}
                    refetch={refetch}
                    setStaffDeet={setStaffDeet}
                  />
                ) : null}
              </div>
            </div>
          </div>
          {cryptoFeeModal && (
            <CrytoFeeModal
              setCryptoFeeModal={setCryptoFeeModal}
              setSellType={setSellType}
              setSellInternalType={setSellInternalType}
              setSellexternalType={setSellexternalType}
              setSellFee={setSellFee}
              setSellInternalFee={setSellInternalFee}
              setSellexternalFee={setSellexternalFee}
              sellType={sellType}
              sellInternalType={sellInternalType}
              sellexternalType={sellexternalType}
              sellFee={sellFee}
              sellInternalFee={sellInternalFee}
              sellexternalFee={sellexternalFee}
              setCryptoReviewModal={setCryptoReviewModal}
            />
          )}
          {cryptoReviewModal && (
            <CryptoReview
              setCryptoReviewModal={setCryptoReviewModal}
              setCryptoFeeModal={setCryptoFeeModal}
              sellType={sellType}
              sellInternalType={sellInternalType}
              sellexternalType={sellexternalType}
              sellFee={sellFee}
              sellInternalFee={sellInternalFee}
              sellexternalFee={sellexternalFee}
              setCryptoRefetch={setCryptoRefetch}
            />
          )}
          {giftcardFeeModal && (
            <GiftcardFeeModal
              setGiftcardFeeModal={setGiftcardFeeModal}
              sellGiftcardType={sellGiftcardType}
              setSellGiftcardType={setSellGiftcardType}
              sellGiftcardFee={sellGiftcardFee}
              setSellGiftcardFee={setSellGiftcardFee}
              setGiftcardReviewModal={setGiftcardReviewModal}
            />
          )}
          {giftcardReviewModal && (
            <GiftcardReview
              setGiftcardReviewModal={setGiftcardReviewModal}
              setGiftcardFeeModal={setGiftcardFeeModal}
              sellGiftcardType={sellGiftcardType}
              sellGiftcardFee={sellGiftcardFee}
              setCryptoRefetch={setCryptoRefetch}
            />
          )}
          {walletFeeModal && (
            <WalletFee
              setWalletFeeModal={setWalletFeeModal}
              setWalletReviewModal={setWalletReviewModal}
              fiatFee={fiatFee}
              setFiatFee={setFiatFee}
            />
          )}
          {walletReviewModal && (
            <WalletReview
              setWalletFeeModal={setWalletFeeModal}
              setWalletReviewModal={setWalletReviewModal}
              fiatFee={fiatFee}
            />
          )}
          {openPayroll && (
            <AddNewMember
              setOpenPayroll={setOpenPayroll}
              setRefetch={setRefetch}
            />
          )}
          {openStaffModal && (
            <StaffDetails
              setOpenStaffModal={setOpenStaffModal}
              staffDeet={staffDeet}
            />
          )}
          {/* {passwordModal ? <PasswordUpdate /> : null} */}
          {/* {successModal ? <Success /> : null} */}
        </div>
      </div>
    </div>
  );
};

export default Settings;
