import {
  growth,
  happiness,
  retention,
  revenue,
  website,
} from "../assets/images";

export const API_URL = process.env.REACT_APP_API_URL;
export const PROD_LINK = "https://admin.deexoptions.com/dashboard";
export const STAGING_LINK = "https://staging.admin.deexoptions.com/dashboard";
export const PROD_LOGIN_LINK = "https://admin.deexoptions.com";
export const STAGING_LOGIN_LINK = "https://staging.admin.deexoptions.com";
export const reports = [
  {
    title: "Revenue",
    image: revenue,
    content:
      "How much does each user generate to/for us per year. How much money did we spend to acquire a user, etc",
  },
  {
    title: "Growth",
    image: growth,
    content:
      "Total users per month or week, there source, if they signed up (activated user), if they buy.",
  },
  {
    title: "Retention",
    image: retention,
    content:
      "Users who came back to login  by theirselves. Resurrected users are users we got back by ourselves.",
  },
  {
    title: "Website",
    image: website,
    content:
      "How much does each user generate to/for us per year. How much money did we spend to acquire a user, etc, ",
  },
  {
    title: "Happiness",
    image: happiness,
    content:
      "App Store rating, NPS scores, customer success rating, customer feedback scores, etc.",
  },
];

export const options = [
  { value: "frontend_developer", label: "Frontend Developer" },
  { value: "backend_developer", label: "Backend Developer" },
  { value: "fullstack_developer", label: "Fullstack Developer" },
  { value: "product_manager", label: "Product Manager" },
  { value: "project_manager", label: "Project Manager" },
  { value: "graphic_designer", label: "Graphic Designer" },
  { value: "ui_ux_designer", label: "UI/UX Designer" },
  { value: "devops_engineer", label: "DevOps Engineer" },
  { value: "data_scientist", label: "Data Scientist" },
  { value: "qa_engineer", label: "QA Engineer" },
  { value: "qa_tester", label: "QA Tester" },
  { value: "mobile_developer", label: "Mobile Developer" },
  { value: "cloud_engineer", label: "Cloud Engineer" },
  { value: "database_administrator", label: "Database Administrator" },
  { value: "cybersecurity_specialist", label: "Cybersecurity Specialist" },
  { value: "technical_writer", label: "Technical Writer" },
  { value: "machine_learning_engineer", label: "Machine Learning Engineer" },
  { value: "it_support_specialist", label: "IT Support Specialist" },
  { value: "software_architect", label: "Software Architect" },
];
