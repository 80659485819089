import React, { useEffect, useState } from "react";
import { noTransaction } from "../../../assets/images";
import axios from "axios";
import { API_URL } from "../../../constant";
import { useAuth } from "../../../context/auth-context";
import { formatAmount } from "../../../utils/FormatPerson";
import { TailSpin } from "react-loader-spinner";

const PayrollList = ({ setOpenStaffModal, setStaffDeet, refetch }: any) => {
  const { token } = useAuth();
  const [staffs, setStaffs] = useState<any[]>([]);
  const [staffLoader, setStaffLoader] = useState<boolean>(false);
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
  const [refetchCount, setRefetchCount] = useState<number>(1);

  useEffect(() => {
    setStaffLoader(true);
    axios
      .get(`${API_URL}api/payroll/staff`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        setStaffLoader(false);

        // Sort staff by creation date in descending order (newest first)
        const sortedStaff = r?.data?.data.sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

        setStaffs(sortedStaff);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
        setStaffLoader(false);
      });
  }, [token, refetch, refetchCount]);
  return (
    <div className="w-full h-[600px]">
      {staffLoader ? (
        <div className="lgss:flex hidden gap-5 w-full mt-8 px-4 h-[600px]">
          <div className="relative w-full space-y-3 overflow-hidden  bg-[#5c6b73] bg-opacity-30 p-8 shadow before:absolute before:inset-0 before:-translate-x-full before:bg-gradient-to-r before:from-transparent before:via-white/40 hover:shadow-lg before:animate-[shimmer_1.3s_infinite]">
            <div className="h-36 w-full  bg-[#E7EBEE]"></div>

            <div className="grid grid-cols-6 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
          </div>
        </div>
      ) : staffs.length >= 1 ? (
        <div>
          <div className="bg-[#0D0D0D] overflow-auto scrollbar-thumb-[#60B7F6] scrollbar-track-white   scrollbar-thin   w-full h-[652px]  ">
            <table className="w-full">
              <thead>
                <tr className="h-[56px] bg-[#1C2227] flex justify-start px-8  items-center ">
                  <th className="text-[#617889] gap-12 cursor-pointer mr-4 flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap ">
                    Name
                  </th>
                  <th className="text-[#617889] gap-12 cursor-pointer mr-4 flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap ">
                    Email
                  </th>
                  <th className="text-[#617889] gap-6 cursor-pointer  flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap ">
                    Role
                  </th>

                  <th className="text-[#617889] text-[15px] w-2/12 mr-4 whitespace-nowrap text-left overflow-hidden">
                    Salary
                  </th>
                  <th className="text-[#617889] gap-4 cursor-pointer flex mr-4 justify-start items-center text-[15px] w-2/12  whitespace-nowrap ">
                    Date Created
                  </th>
                  <th className="text-[#617889] gap-12 cursor-pointer flex justify-center items-center text-[15px] w-2/12  whitespace-nowrap ">
                    Action
                  </th>
                </tr>
              </thead>
              {staffs.map((staff: any, index: React.Key | null | undefined) => {
                const deleteStaff = () => {
                  if (loadingIndex !== staff?._id) {
                    setLoadingIndex((prev) =>
                      prev === staff?._id ? null : staff?._id
                    );
                    axios
                      .delete(`${API_URL}api/payroll/staff/${staff?._id}`, {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      })
                      .then((r: any) => {
                        setLoadingIndex(null);
                        setRefetchCount((prev: number) => prev + 1);
                      })
                      .catch((e: any) => {
                        setLoadingIndex(null);
                        console.log(e.response.data.message);
                      });
                  }
                };
                return (
                  <tbody
                    onClick={() => {
                      setStaffDeet(staff);
                      setOpenStaffModal(true);
                    }}
                    key={index}
                    className="cursor-pointer"
                  >
                    <tr className="h-[56px] w-full flex justify-start  px-8  items-center">
                      <td className="text-white text-[15px] w-2/12 whitespace-nowrap capitalize mr-4 text-left overflow-hidden">
                        {staff?.fullName?.toUpperCase() ?? "--- ---"}
                      </td>
                      <td className="text-white text-[15px] w-2/12 whitespace-nowrap mr-4 text-left overflow-hidden">
                        {staff?.email ?? "----"}
                      </td>
                      <td className="text-white text-[15px] w-2/12 whitespace-nowrap mr-4 text-left overflow-hidden">
                        {staff?.role ?? "----"}
                      </td>

                      <td className="text-white text-[15px] w-2/12 whitespace-nowrap mr-4 text-left overflow-hidden">
                        ₦ {formatAmount(staff?.salary) ?? "0.00"}
                      </td>

                      <td className="text-white text-[15px] w-2/12 whitespace-nowrap mr-4 text-left overflow-hidden">
                        {(() => {
                          const dateString = staff?.createdAt;
                          const date = new Date(dateString);

                          const day = date.getDate();
                          const ordinalSuffix = (day: number) => {
                            switch (day) {
                              case 1:
                              case 21:
                              case 31:
                                return "st";
                              case 2:
                              case 22:
                                return "nd";
                              case 3:
                              case 23:
                                return "rd";
                              default:
                                return "th";
                            }
                          };

                          const formattedDate = `${date.toLocaleString(
                            "default",
                            { month: "short" }
                          )} ${day}${ordinalSuffix(
                            day
                          )}, ${date.getFullYear()}, ${date.toLocaleString(
                            "default",
                            {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            }
                          )}`;

                          return formattedDate;
                        })()}
                      </td>
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="w-2/12 flex  justify-center items-center "
                      >
                        <button
                          disabled={loadingIndex === staff?._id}
                          onClick={deleteStaff}
                          className="px-4 py-2 border border-gray-800 rounded-lg font-semibold  gap-2 text-[12px]  text-[#DF4E4E]"
                        >
                          {loadingIndex === staff?._id ? (
                            <TailSpin color="#FFFFFF" height={30} width={30} />
                          ) : (
                            "Delete Staff"
                          )}
                        </button>
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="mt-1 w-full h-[1px] bg-[#13181B]" />
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-col justify-start h-full pt-12 items-center">
          <img src={noTransaction} alt="" />
          <h4 className="font-bold text-[#869AA9] pt-2">No staff on payroll</h4>
          <p className=" text-[#869AA9] pt-2 text-[12px]">
            All staffs on payroll will show up here
          </p>
        </div>
      )}
    </div>
  );
};

export default PayrollList;
