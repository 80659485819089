import React from "react";
import { FiCheck } from "react-icons/fi";

const PayrollSuccess = ({ setOpenPayrollSuccess }: any) => {
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-60 backdrop-blur-sm">
      <div className="w-11/12 mds:w-10/12 lgss:w-[35%] transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F] ">
        <div className="mt-6 flex flex-col justify-center items-center">
          <FiCheck className="text-[#5EAE68] text-[24px]" />
          <h4 className="text-[20px] mt-4 text-white font-semibold font-sora">
            Success
          </h4>
          <h4 className="text-[15px] mt-4 text-[#A0A9AD] font-medium">
            Payroll created successfully.
          </h4>
          <button
            onClick={() => {
              setOpenPayrollSuccess(false);
            }}
            className={
              true
                ? " w-full rounded-xl py-3 flex justify-center items-center text-white text-[16px] font-bold mt-6 bg-secondary_button"
                : " w-full rounded-xl py-3 flex justify-center items-center text-gray-200 text-[16px] font-bold mt-6 bg-gray-600"
            }
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default PayrollSuccess;
