import axios from "axios";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaTimesCircle } from "react-icons/fa";
import { API_URL } from "../../../constant";
import { formatAmount } from "../../../utils/FormatPerson";

const StaffDetails = ({ setOpenStaffModal, staffDeet }: any) => {
  const [copyAccount, setCopyAccount] = useState<boolean>(false);
  const [allBanks, setAllBanks] = useState<any[]>([]);
  // console.log(staffDeet);
  useEffect(() => {
    axios
      .get(`${API_URL}api/banks`)
      .then((r: any) => {
        setAllBanks(r.data.data);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, []);
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-80 backdrop-blur-sm">
      <div className="w-11/12 lgss:w-2/5 transition-all p-6 ease-in duration-1000  rounded-xl bg-[#0D0D0D] ">
        <div className="flex  pt-4 justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Staff Details
          </h4>
          <FaTimesCircle
            onClick={() => setOpenStaffModal((prev: any) => !prev)}
            className="text-white text-[24px] cursor-pointer"
          />
        </div>
        <div className="w-full mt-6">
          <div className="w-full mt-4 flex justify-between items-center">
            <h4 className="text-[#AEBCC6] text-[15px] font-semibold">
              Full name
            </h4>
            <h4 className="text-[#869AA9] text-[15px] font-medium">
              {staffDeet?.fullName?.toUpperCase() ?? "--- ---"}
            </h4>
          </div>
          <div className="w-full mt-4 flex justify-between items-center">
            <h4 className="text-[#AEBCC6] text-[15px] font-semibold">Email</h4>
            <h4 className="text-[#869AA9] text-[15px] font-medium">
              {staffDeet?.email ?? "---"}
            </h4>
          </div>
          <div className="w-full mt-4 flex justify-between items-center">
            <h4 className="text-[#AEBCC6] text-[15px] font-semibold">Role</h4>
            <h4 className="text-[#869AA9] text-[15px] font-medium">
              {staffDeet?.role ?? "---"}
            </h4>
          </div>
          <div className="w-full mt-4 flex justify-between items-center">
            <h4 className="text-[#AEBCC6] text-[15px] font-semibold">Salary</h4>
            <h4 className="text-[#869AA9] text-[15px] font-medium">
              ₦ {formatAmount(staffDeet?.salary) ?? "0.00"}
            </h4>
          </div>
          <div className="w-full mt-4 flex justify-between items-center">
            <h4 className="text-[#AEBCC6] text-[15px] font-semibold">
              Cash destination
            </h4>

            <CopyToClipboard
              text={staffDeet?.bankDetails?.accountNumber ?? ""}
              onCopy={() => {
                setCopyAccount(true);
                setTimeout(() => {
                  setCopyAccount(false);
                }, 3000);
              }}
            >
              {copyAccount ? (
                <h4 className="text-[12px] xs:text-[14px] text-[#869AA9]">
                  Copied
                </h4>
              ) : (
                <div className="text-[#0C84DA] cursor-pointer flex gap-2 justify-center items-center font-bold text-[13px] ">
                  {staffDeet?.bankDetails?.accountNumber ?? ""}{" "}
                  {allBanks
                    .filter(
                      (allBank) =>
                        allBank.code === staffDeet?.bankDetails?.bankCode
                    )
                    .map((bank, index) => (
                      <h5 key={index} className="text-[#869AA9]">
                        {bank.name}
                      </h5>
                    ))}
                </div>
              )}
            </CopyToClipboard>
          </div>
          <div className="w-full mt-4 flex justify-between items-center">
            <h4 className="text-[#AEBCC6] text-[15px] font-semibold">Role</h4>
            <h4 className="text-[#869AA9] text-[15px] font-medium">
              {staffDeet?.bankDetails?.accountName ?? ""}
            </h4>
          </div>
          <div className="w-full mt-4 flex justify-between items-center">
            <h4 className="text-[#AEBCC6] text-[15px] font-semibold">
              Date Created
            </h4>
            <h4 className="text-[#869AA9] text-[15px] font-medium ">
              {(() => {
                const dateString = staffDeet?.createdAt;
                const date = new Date(dateString);

                const day = date.getDate();
                const ordinalSuffix = (day: number) => {
                  switch (day) {
                    case 1:
                    case 21:
                    case 31:
                      return "st";
                    case 2:
                    case 22:
                      return "nd";
                    case 3:
                    case 23:
                      return "rd";
                    default:
                      return "th";
                  }
                };

                const formattedDate = `${date.toLocaleString("default", {
                  month: "short",
                })} ${day}${ordinalSuffix(
                  day
                )}, ${date.getFullYear()}, ${date.toLocaleString("default", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}`;

                return formattedDate;
              })()}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffDetails;
