import axios from "axios";
import React, { useEffect, useState } from "react";
import { noTransaction } from "../../assets/images";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";
import { TailSpin } from "react-loader-spinner";
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { useLocation } from "react-router-dom";

const CryptoOrders = ({
  setOrderModal,
  setUserId,
  setTsxnId,
  setCurrency,
  setCurrencyUSD,
  setCurrencyNGN,
  setStatus,
  setPayoutStatus,
  setNetwork,
  setDate,
  refetchCount,
  setCryptoRate,
  // status,
  // payoutStatus,
  currencyNGNGiftcard,
  setImageURL,
  setStatusGiftcard,
  setDateGiftcard,
  setCurrencyUSDGiftcard,
  setCurrencyNGNGiftcard,
  setUserIdGiftcard,
  setTxnId,
  setCardType,
  setEcode,
  setOrderGiftcardModal,
  setOrderss,
  setNewOrders,
}: any) => {
  const { token, transactionId } = useAuth();
  const [payouts, setPayouts] = useState<any[]>([]);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [payoutLoader, setPayoutLoader] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | "">("");
  const [sortOrderType, setSortOrderType] = useState<"asc" | "desc" | "">("");
  const [sortOrderDate, setSortOrderDate] = useState<"asc" | "desc" | "">("");
  const [sortOrderAmount, setSortOrderAmount] = useState<"asc" | "desc" | "">(
    ""
  );
  const [page, setPage] = useState<number>(1);

  const [currentpage, setCurrentPage] = useState<number>();

  const [totalPages, setTotalPages] = useState<number>(1);
  const [transactionCount, setTransactionCount] = useState<number>(1);
  const location = useLocation();
  const timeout = transactionId ? 500 : 1500;

  useEffect(() => {
    setPayoutLoader(true);
    setTimeout(() => {
      axios
        .get(
          `${API_URL}api/transactions?page=${page}&excludeTypes=payouts,deex-deposits,deex-asset-swaps,deex-fiat-deposits&limit=${
            location.pathname === "/dashboard"
              ? 10
              : transactionId
              ? transactionCount
              : 25
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          const transactions = r.data.data.transactions;
          // console.log(transactions);
          if (transactionId) {
            const newTransaction = transactions.find(
              (txn: any) => txn._id === transactionId
            );
            setPayouts(newTransaction ? [newTransaction] : []);
          } else {
            setPayouts(transactions);
          }
          setTotalPages(r.data.data.meta.totalPages);
          setCurrentPage(r.data.data.meta.page);
          setTransactionCount(r.data.data.meta.transactionsCount);
          setPayoutLoader(false);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
          setPayoutLoader(false);
        });
    }, timeout);
  }, [
    token,
    refetchCount,
    page,
    location.pathname,
    transactionId,
    transactionCount,
    timeout,
  ]);
  useEffect(() => {
    axios
      .get(
        `${API_URL}api/transactions?excludeTypes=payouts,deex-crypto-deposits,deex-crypto-withdrawals,deex-asset-swaps,deex-fiat-deposits&limit=${transactionCount}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r: any) => {
        setNewOrders(r?.data?.data?.transactions);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [setNewOrders, token, transactionCount]);

  useEffect(() => {
    if (sortOrder === "asc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const nameA = `${a.userDetails.profile.firstName} ${a.userDetails.profile.lastName}`;
          const nameB = `${b.userDetails.profile.firstName} ${b.userDetails.profile.lastName}`;
          return nameA.localeCompare(nameB);
        })
      );
    } else if (sortOrder === "desc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const nameA = `${a.userDetails.profile.firstName} ${a.userDetails.profile.lastName}`;
          const nameB = `${b.userDetails.profile.firstName} ${b.userDetails.profile.lastName}`;
          return nameB.localeCompare(nameA);
        })
      );
    } else {
      setSortedData(payouts);
    }
  }, [sortOrder, payouts]);
  useEffect(() => {
    if (sortOrderType === "asc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const nameA = `${
            a.transactionType === "crypto-deposit" ? a.currency : a.card.name
          }`;
          const nameB = `${
            b.transactionType === "crypto-deposit" ? b.currency : b.card.name
          }`;
          return nameA.localeCompare(nameB);
        })
      );
    } else if (sortOrderType === "desc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const nameA = `${
            a.transactionType === "crypto-deposit" ? a.currency : a.card.name
          }`;
          const nameB = `${
            b.transactionType === "crypto-deposit" ? b.currency : b.card.name
          }`;
          return nameB.localeCompare(nameA);
        })
      );
    } else {
      setSortedData(payouts);
    }
  }, [sortOrderType, payouts]);

  useEffect(() => {
    if (sortOrderAmount === "asc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const amountA =
            a.transactionType === "crypto-deposit"
              ? a.usdtAmount
              : a.tradeValue.reduce(
                  (total: any, item: { amount: any }) => total + item.amount,
                  0
                );
          const amountB =
            b.transactionType === "crypto-deposit"
              ? b.usdtAmount
              : b.tradeValue.reduce(
                  (total: any, item: { amount: any }) => total + item.amount,
                  0
                );
          return amountA - amountB;
        })
      );
    } else if (sortOrderAmount === "desc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const amountA =
            a.transactionType === "crypto-deposit"
              ? a.usdtAmount
              : a.tradeValue.reduce(
                  (total: any, item: { amount: any }) => total + item.amount,
                  0
                );
          const amountB =
            b.transactionType === "crypto-deposit"
              ? b.usdtAmount
              : b.tradeValue.reduce(
                  (total: any, item: { amount: any }) => total + item.amount,
                  0
                );
          return amountB - amountA;
        })
      );
    } else {
      setSortedData(payouts);
    }
  }, [sortOrderAmount, payouts]);

  useEffect(() => {
    if (sortOrderDate === "asc") {
      setSortedData(
        [...payouts].sort(
          (a, b) =>
            new Date(a.createdAt || a.processedAt).getTime() -
            new Date(b.createdAt || b.processedAt).getTime()
        )
      );
    } else if (sortOrderDate === "desc") {
      setSortedData(
        [...payouts].sort(
          (a, b) =>
            new Date(b.createdAt || b.processedAt).getTime() -
            new Date(a.createdAt || a.processedAt).getTime()
        )
      );
    } else {
      setSortedData(payouts);
    }
  }, [sortOrderDate, payouts]);

  //
  return (
    <div className="w-full  h-full">
      {payoutLoader ? (
        <div className="lgss:flex gap-5 w-full hidden  h-[520px]">
          <div className="relative w-full space-y-3 overflow-hidden  bg-[#5c6b73] bg-opacity-30 p-8 shadow before:absolute before:inset-0 before:-translate-x-full before:bg-gradient-to-r before:from-transparent before:via-white/40 hover:shadow-lg before:animate-[shimmer_1.3s_infinite]">
            <div className="h-24 w-full  bg-[#E7EBEE]"></div>

            <div className="grid grid-cols-5 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-5 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-5 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-5 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-5 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-5 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-5 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-5 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
          </div>
        </div>
      ) : sortedData.length >= 1 ? (
        <div className="bg-[#0D0D0D] hidden overflow-auto scrollbar-thumb-[#60B7F6] scrollbar-track-white  scrollbar-thin   lgss:flex lgss:flex-col w-full h-[512px] mb-8 ">
          <table className="w-full">
            <thead>
              <tr className="h-[56px] bg-[#1C2227] flex justify-start px-8 items-center ">
                <th
                  onClick={() => {
                    if (sortOrder === "desc") {
                      setSortOrder("asc");
                    } else {
                      setSortOrder("desc");
                    }
                  }}
                  className="text-[#617889] gap-12 cursor-pointer mr-4 flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap "
                >
                  Name
                  <div className="flex flex-col justify-center items-center">
                    <TiArrowSortedUp
                      className={
                        sortOrder === "asc"
                          ? "text-[#BDBDBE] text-[14px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                    <TiArrowSortedDown
                      className={
                        sortOrder === "desc"
                          ? "text-[#BDBDBE] text-[14px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                  </div>
                </th>
                <th
                  onClick={() => {
                    if (sortOrderType === "desc") {
                      setSortOrderType("asc");
                    } else {
                      setSortOrderType("desc");
                    }
                  }}
                  className="text-[#617889] gap-12 cursor-pointer flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap "
                >
                  Asset
                  <div className="flex flex-col justify-center items-center">
                    <TiArrowSortedUp
                      className={
                        sortOrderType === "asc"
                          ? "text-[#BDBDBE] text-[14px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                    <TiArrowSortedDown
                      className={
                        sortOrderType === "desc"
                          ? "text-[#BDBDBE] text-[14px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                  </div>
                </th>
                <th className="text-[#617889] text-[15px] whitespace-nowrap w-2/12 text-left">
                  Type
                </th>
                <th
                  onClick={() => {
                    if (sortOrderAmount === "desc") {
                      setSortOrderAmount("asc");
                    } else {
                      setSortOrderAmount("desc");
                    }
                  }}
                  className="text-[#617889] gap-8 cursor-pointer flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap "
                >
                  Amount
                  <div className="flex flex-col justify-center items-center">
                    <TiArrowSortedUp
                      className={
                        sortOrderAmount === "asc"
                          ? "text-[#BDBDBE] text-[14px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                    <TiArrowSortedDown
                      className={
                        sortOrderAmount === "desc"
                          ? "text-[#BDBDBE] text-[14px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                  </div>
                </th>
                <th className="text-[#617889] text-[15px] mr-3 whitespace-nowrap w-2/12 text-left">
                  Trans ID
                </th>
                <th
                  onClick={() => {
                    if (sortOrderDate === "desc") {
                      setSortOrderDate("asc");
                    } else {
                      setSortOrderDate("desc");
                    }
                  }}
                  className="text-[#617889] gap-12 cursor-pointer flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap "
                >
                  Date
                  <div className="flex flex-col justify-center items-center">
                    <TiArrowSortedUp
                      className={
                        sortOrderDate === "desc"
                          ? "text-[#BDBDBE] text-[12px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                    <TiArrowSortedDown
                      className={
                        sortOrderDate === "asc"
                          ? "text-[#BDBDBE] text-[12px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                  </div>
                </th>
                <th className="text-[#617889] text-[15px] whitespace-nowrap w-2/12 text-center">
                  Status
                </th>
              </tr>
            </thead>
            {sortedData.map((payout, index) => {
              return (
                <tbody
                  onClick={() => {
                    if (payout.transactionType !== "gift-card-transactions") {
                      setOrderModal((prev: any) => !prev);
                      setUserId(payout.userId);
                      setTsxnId(payout._id);
                      setOrderss(payout);
                      if (
                        payout.transactionType !== "deex-crypto-withdrawals"
                      ) {
                        if (payout?.currency === "USDT" || "usdt") {
                          setCryptoRate(payout.rates[0].rate);
                        } else if (payout.currency === "BTC" || "btc") {
                          setCryptoRate(payout.rates[1].rate);
                        } else setCryptoRate(0);
                      }
                      setCurrency(payout.currency);
                      if (
                        payout.transactionType === "deex-crypto-withdrawals"
                      ) {
                        setCurrencyUSD(payout.amount);
                      } else {
                        setCurrencyUSD(payout.usdtAmount.toFixed(2));
                      }
                      if (
                        payout.transactionType === "deex-crypto-withdrawals"
                      ) {
                        setCurrencyNGN(0);
                      } else {
                        if (payout.currency === "USDT" || "usdt") {
                          setCurrencyNGN(
                            payout.usdtAmount * payout.rates[0].rate
                          );
                        } else if (payout.currency === "BTC" || "btc") {
                          setCurrencyNGN(
                            (payout.usdtAmount * payout.rates[1].rate).toFixed(
                              2
                            )
                          );
                        }
                      }

                      if (payout.payout) {
                        setPayoutStatus(true);
                      } else {
                        setPayoutStatus(false);
                        if (payout.processingState) {
                          setStatus(payout.processingState);
                        }
                      }
                      setNetwork(payout.network);
                      setDate(() => {
                        const dateString =
                          payout.createdAt || payout.processedAt;
                        const date = new Date(dateString);

                        const day = date.getDate();
                        const ordinalSuffix = (day: number) => {
                          switch (day) {
                            case 1:
                            case 21:
                            case 31:
                              return "st";
                            case 2:
                            case 22:
                              return "nd";
                            case 3:
                            case 23:
                              return "rd";
                            default:
                              return "th";
                          }
                        };

                        const formattedDate = `${date.toLocaleString(
                          "default",
                          {
                            month: "short",
                          }
                        )} ${day}${ordinalSuffix(
                          day
                        )}, ${date.getFullYear()} | ${date.toLocaleString(
                          "default",
                          {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        )}`;

                        return formattedDate;
                      });
                    } else {
                      setCardType(payout);
                      setCurrencyNGNGiftcard(() => {
                        let totalAmount = 0;
                        if (payout.country) {
                          if (
                            payout.card &&
                            payout.tradeValue &&
                            Array.isArray(payout.tradeValue)
                          ) {
                            payout.tradeValue.forEach(
                              (trade: { range: any; amount: number }) => {
                                const range = trade.range;
                                let rate = 0;

                                // Find the rate based on the new structure
                                const rateInfo = payout.card.rates.find(
                                  (r: {
                                    range: {
                                      min: number;
                                      max: number;
                                    };
                                    type: any;
                                  }) =>
                                    r.range.min <= range.min &&
                                    r.range.max >= range.max &&
                                    r.type === payout.type
                                );

                                if (rateInfo) {
                                  rate = rateInfo.rate;
                                  const amount = trade.amount * rate;
                                  totalAmount += amount;
                                }
                              }
                            );
                          }
                        } else {
                          if (
                            payout.card &&
                            payout.tradeValue &&
                            Array.isArray(payout.tradeValue)
                          ) {
                            payout.tradeValue.forEach(
                              (trade: { range: any; amount: number }) => {
                                const range = trade.range;
                                let rate = 0;

                                switch (payout.type) {
                                  case "physicalActivationReceipt":
                                    rate =
                                      payout.card.country.rates.physicalActivationReceipt.find(
                                        (r: {
                                          range: {
                                            min: number;
                                            max: number;
                                          };
                                        }) =>
                                          r.range.min <= range.min &&
                                          r.range.max >= range.max
                                      )?.rate;
                                    break;
                                  case "physicalCashReceipt":
                                    rate =
                                      payout.card.country.rates.physicalCashReceipt.find(
                                        (r: {
                                          range: {
                                            min: number;
                                            max: number;
                                          };
                                        }) =>
                                          r.range.min <= range.min &&
                                          r.range.max >= range.max
                                      )?.rate;
                                    break;
                                  case "physicalCreditCardReceipt":
                                    rate =
                                      payout.card.country.rates.physicalCreditCardReceipt.find(
                                        (r: {
                                          range: {
                                            min: number;
                                            max: number;
                                          };
                                        }) =>
                                          r.range.min <= range.min &&
                                          r.range.max >= range.max
                                      )?.rate;
                                    break;
                                  case "physicalDebitCardReceipt":
                                    rate =
                                      payout.card.country.rates.physicalDebitCardReceipt.find(
                                        (r: {
                                          range: {
                                            min: number;
                                            max: number;
                                          };
                                        }) =>
                                          r.range.min <= range.min &&
                                          r.range.max >= range.max
                                      )?.rate;
                                    break;
                                  case "physicalNoReceipt":
                                    rate =
                                      payout.card.country.rates.physicalNoreceipt.find(
                                        (r: {
                                          range: {
                                            min: number;
                                            max: number;
                                          };
                                        }) =>
                                          r.range.min <= range.min &&
                                          r.range.max >= range.max
                                      )?.rate;
                                    break;
                                  case "ecode":
                                    rate = payout.card.country.rates.ecode.find(
                                      (r: {
                                        range: {
                                          min: number;
                                          max: number;
                                        };
                                      }) =>
                                        r.range.min <= range.min &&
                                        r.range.max >= range.max
                                    )?.rate;
                                    break;
                                  default:
                                    break;
                                }

                                if (rate) {
                                  const amount = trade.amount * rate;
                                  totalAmount += amount;
                                }
                              }
                            );
                          }
                        }
                        return totalAmount;
                      });

                      setCurrencyUSDGiftcard(
                        payout.tradeValue
                          .reduce(
                            (total: any, item: { amount: any }) =>
                              total + item.amount,
                            0
                          )
                          .toLocaleString("en-US")
                      );
                      setStatusGiftcard(payout.status);
                      setTxnId(payout._id);
                      setUserIdGiftcard(payout.userId);
                      setImageURL(payout.images);
                      setDateGiftcard(() => {
                        const dateString =
                          payout.createdAt || payout.processedAt;
                        const date = new Date(dateString);

                        const day = date.getDate();
                        const ordinalSuffix = (day: number) => {
                          switch (day) {
                            case 1:
                            case 21:
                            case 31:
                              return "st";
                            case 2:
                            case 22:
                              return "nd";
                            case 3:
                            case 23:
                              return "rd";
                            default:
                              return "th";
                          }
                        };

                        const formattedDate = `${date.toLocaleString(
                          "default",
                          {
                            month: "short",
                          }
                        )} ${day}${ordinalSuffix(
                          day
                        )}, ${date.getFullYear()} | ${date.toLocaleString(
                          "default",
                          {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        )}`;

                        return formattedDate;
                      });
                      if (payout.code) {
                        setEcode(payout.code);
                      }
                      setOrderGiftcardModal((prev: any) => !prev);
                    }
                  }}
                  key={index}
                  className="w-full cursor-pointer"
                >
                  <tr className="h-[56px] w-full flex justify-start px-8   items-center">
                    <td className="text-white text-[14px] w-2/12 capitalize whitespace-nowrap mr-4 text-left overflow-hidden">
                      {payout.userDetails.profile.firstName}{" "}
                      {payout.userDetails.profile.lastName}
                    </td>
                    <td className="text-white text-[14px] w-2/12 uppercase whitespace-nowrap text-left overflow-hidden">
                      {payout.transactionType === "gift-card-transactions"
                        ? payout.card?.name
                        : payout.currency}
                    </td>
                    <td className="text-white text-[14px] w-2/12 whitespace-nowrap text-left overflow-hidden">
                      Sell
                    </td>
                    <td className="text-white text-[14px] w-2/12 whitespace-nowrap text-left overflow-hidden">
                      {`$${
                        payout.transactionType === "gift-card-transactions"
                          ? payout.tradeValue
                              .reduce(
                                (total: any, item: { amount: any }) =>
                                  total + item.amount,
                                0
                              )
                              .toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                          : payout.transactionType === "deex-crypto-withdrawals"
                          ? payout.amount?.toFixed(2)
                          : payout?.usdtAmount?.toFixed(2)
                      }`}
                    </td>
                    <td className="text-white text-[14px] mr-4 w-2/12 whitespace-nowrap text-left overflow-hidden">
                      {payout._id}
                    </td>

                    <td className="text-white text-[14px] w-2/12 whitespace-nowrap text-left overflow-hidden">
                      {(() => {
                        const dateString =
                          payout.createdAt || payout.processedAt;
                        const date = new Date(dateString);

                        const day = date.getDate();
                        const ordinalSuffix = (day: number) => {
                          switch (day) {
                            case 1:
                            case 21:
                            case 31:
                              return "st";
                            case 2:
                            case 22:
                              return "nd";
                            case 3:
                            case 23:
                              return "rd";
                            default:
                              return "th";
                          }
                        };

                        const formattedDate = `${date.toLocaleString(
                          "default",
                          {
                            month: "short",
                          }
                        )} ${day}${ordinalSuffix(
                          day
                        )}, ${date.getFullYear()}, ${date.toLocaleString(
                          "default",
                          {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        )}`;

                        return formattedDate;
                      })()}
                    </td>
                    {payout.transactionType === "crypto-deposit" ||
                    payout.transactionType === "deex-crypto-withdrawals" ? (
                      <td className="text-white text-[15px] w-2/12 whitespace-nowrap flex justify-end  overflow-hidden">
                        <h4 className="text-[#03C668] flex justify-center items-center w-4/5 bg-[#013019] tracking-wider h-[26px] rounded-full text-[9px] font-bold">
                          COMPLETED
                        </h4>
                      </td>
                    ) : (
                      <td className="text-white text-[15px] w-2/12 whitespace-nowrap flex justify-end  overflow-hidden">
                        <h4
                          className={
                            payout.status === "PENDING"
                              ? "text-[#CB9200] flex justify-center items-center w-4/5 bg-[#181818] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                              : payout.status === "IN PROGRESS"
                              ? "text-[#60B7F6] flex justify-center items-center w-4/5 bg-[#181818] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                              : payout.status === "COMPLETED"
                              ? "text-[#03C668] flex justify-center items-center w-4/5 bg-[#0E2B1F] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                              : ""
                          }
                        >
                          {payout.status}
                        </h4>
                      </td>
                    )}
                  </tr>
                  <tr className="w-full">
                    <td className="mt-1 w-full h-[1px] bg-[#13181B]" />
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      ) : (
        <div className="flex flex-col justify-center mt-16 mb-60 w-full lgss:mt-24 items-center">
          <img src={noTransaction} alt="" />
          <h4 className="font-bold text-[#869AA9] pt-2 text-[14px]">
            No recent order
          </h4>
          <p className=" text-[#869AA9] pt-2 text-[12px]">
            All your orders will show up here
          </p>
        </div>
      )}
      <div className="bg-[#1C2227] overflow-auto scrollbar-thumb-[#AEBCC6]  scrollbar-thin   lgss:hidden flex flex-col w-full h-[652px]   mb-6 px-5 xs:px-8 pt-4  ">
        {payoutLoader ? (
          <div className="mt-6 flex lgss:hidden justify-center items-center">
            <TailSpin color="#0B75C2" height={50} width={70} />
          </div>
        ) : sortedData.length >= 1 ? (
          <div>
            {sortedData.map((payout, index) => (
              <div
                className="w-full mt-4 cursor-pointer"
                key={index}
                onClick={() => {
                  if (payout.transactionType !== "gift-card-transactions") {
                    setOrderModal((prev: any) => !prev);
                    setUserId(payout.userId);
                    setTsxnId(payout._id);
                    setOrderss(payout);
                    if (payout.transactionType !== "deex-crypto-withdrawals") {
                      if (payout?.currency === "USDT" || "usdt") {
                        setCryptoRate(payout.rates[0].rate);
                      } else if (payout.currency === "BTC" || "btc") {
                        setCryptoRate(payout.rates[1].rate);
                      } else setCryptoRate(0);
                    }
                    setCurrency(payout.currency);
                    if (payout.transactionType === "deex-crypto-withdrawals") {
                      setCurrencyUSD(payout.amount);
                    } else {
                      setCurrencyUSD(payout.usdtAmount.toFixed(2));
                    }
                    if (payout.transactionType === "deex-crypto-withdrawals") {
                      setCurrencyNGN(0);
                    } else {
                      if (payout.currency === "USDT" || "usdt") {
                        setCurrencyNGN(
                          payout.usdtAmount * payout.rates[0].rate
                        );
                      } else if (payout.currency === "BTC" || "btc") {
                        setCurrencyNGN(
                          (payout.usdtAmount * payout.rates[1].rate).toFixed(2)
                        );
                      }
                    }

                    if (payout.payout) {
                      setPayoutStatus(true);
                    } else {
                      setPayoutStatus(false);
                      if (payout.processingState) {
                        setStatus(payout.processingState);
                      }
                    }
                    setNetwork(payout.network);
                    setDate(() => {
                      const dateString = payout.createdAt || payout.processedAt;
                      const date = new Date(dateString);

                      const day = date.getDate();
                      const ordinalSuffix = (day: number) => {
                        switch (day) {
                          case 1:
                          case 21:
                          case 31:
                            return "st";
                          case 2:
                          case 22:
                            return "nd";
                          case 3:
                          case 23:
                            return "rd";
                          default:
                            return "th";
                        }
                      };

                      const formattedDate = `${date.toLocaleString("default", {
                        month: "short",
                      })} ${day}${ordinalSuffix(
                        day
                      )}, ${date.getFullYear()} | ${date.toLocaleString(
                        "default",
                        {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }
                      )}`;

                      return formattedDate;
                    });
                  } else {
                    setCardType(payout);
                    setCurrencyNGNGiftcard(() => {
                      let totalAmount = 0;
                      if (payout.country) {
                        if (
                          payout.card &&
                          payout.tradeValue &&
                          Array.isArray(payout.tradeValue)
                        ) {
                          payout.tradeValue.forEach(
                            (trade: { range: any; amount: number }) => {
                              const range = trade.range;
                              let rate = 0;

                              // Find the rate based on the new structure
                              const rateInfo = payout.card.rates.find(
                                (r: {
                                  range: {
                                    min: number;
                                    max: number;
                                  };
                                  type: any;
                                }) =>
                                  r.range.min <= range.min &&
                                  r.range.max >= range.max &&
                                  r.type === payout.type
                              );

                              if (rateInfo) {
                                rate = rateInfo.rate;
                                const amount = trade.amount * rate;
                                totalAmount += amount;
                              }
                            }
                          );
                        }
                      } else {
                        if (
                          payout.card &&
                          payout.tradeValue &&
                          Array.isArray(payout.tradeValue)
                        ) {
                          payout.tradeValue.forEach(
                            (trade: { range: any; amount: number }) => {
                              const range = trade.range;
                              let rate = 0;

                              switch (payout.type) {
                                case "physicalActivationReceipt":
                                  rate =
                                    payout.card.country.rates.physicalActivationReceipt.find(
                                      (r: {
                                        range: {
                                          min: number;
                                          max: number;
                                        };
                                      }) =>
                                        r.range.min <= range.min &&
                                        r.range.max >= range.max
                                    )?.rate;
                                  break;
                                case "physicalCashReceipt":
                                  rate =
                                    payout.card.country.rates.physicalCashReceipt.find(
                                      (r: {
                                        range: {
                                          min: number;
                                          max: number;
                                        };
                                      }) =>
                                        r.range.min <= range.min &&
                                        r.range.max >= range.max
                                    )?.rate;
                                  break;
                                case "physicalCreditCardReceipt":
                                  rate =
                                    payout.card.country.rates.physicalCreditCardReceipt.find(
                                      (r: {
                                        range: {
                                          min: number;
                                          max: number;
                                        };
                                      }) =>
                                        r.range.min <= range.min &&
                                        r.range.max >= range.max
                                    )?.rate;
                                  break;
                                case "physicalDebitCardReceipt":
                                  rate =
                                    payout.card.country.rates.physicalDebitCardReceipt.find(
                                      (r: {
                                        range: {
                                          min: number;
                                          max: number;
                                        };
                                      }) =>
                                        r.range.min <= range.min &&
                                        r.range.max >= range.max
                                    )?.rate;
                                  break;
                                case "physicalNoReceipt":
                                  rate =
                                    payout.card.country.rates.physicalNoreceipt.find(
                                      (r: {
                                        range: {
                                          min: number;
                                          max: number;
                                        };
                                      }) =>
                                        r.range.min <= range.min &&
                                        r.range.max >= range.max
                                    )?.rate;
                                  break;
                                case "ecode":
                                  rate = payout.card.country.rates.ecode.find(
                                    (r: {
                                      range: {
                                        min: number;
                                        max: number;
                                      };
                                    }) =>
                                      r.range.min <= range.min &&
                                      r.range.max >= range.max
                                  )?.rate;
                                  break;
                                default:
                                  break;
                              }

                              if (rate) {
                                const amount = trade.amount * rate;
                                totalAmount += amount;
                              }
                            }
                          );
                        }
                      }
                      return totalAmount;
                    });

                    setCurrencyUSDGiftcard(
                      payout.tradeValue
                        .reduce(
                          (total: any, item: { amount: any }) =>
                            total + item.amount,
                          0
                        )
                        .toLocaleString("en-US")
                    );
                    setStatusGiftcard(payout.status);
                    setTxnId(payout._id);
                    setUserIdGiftcard(payout.userId);
                    setImageURL(payout.images);
                    setDateGiftcard(() => {
                      const dateString = payout.createdAt || payout.processedAt;
                      const date = new Date(dateString);

                      const day = date.getDate();
                      const ordinalSuffix = (day: number) => {
                        switch (day) {
                          case 1:
                          case 21:
                          case 31:
                            return "st";
                          case 2:
                          case 22:
                            return "nd";
                          case 3:
                          case 23:
                            return "rd";
                          default:
                            return "th";
                        }
                      };

                      const formattedDate = `${date.toLocaleString("default", {
                        month: "short",
                      })} ${day}${ordinalSuffix(
                        day
                      )}, ${date.getFullYear()} | ${date.toLocaleString(
                        "default",
                        {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }
                      )}`;

                      return formattedDate;
                    });
                    if (payout.code) {
                      setEcode(payout.code);
                    }
                    setOrderGiftcardModal((prev: any) => !prev);
                  }
                }}
              >
                <div className="w-full justify-between flex items-center">
                  <div>
                    <h4 className="text-white text-[14px] font-medium">
                      {payout.transactionType === "gift-card-transactions"
                        ? payout.card?.name
                        : payout.currency}
                    </h4>
                    <p className="mt-4 font-medium text-[13px] text-[#869AA9]">
                      {(() => {
                        const dateString =
                          payout.createdAt || payout.processedAt;
                        const date = new Date(dateString);

                        const day = date.getDate();
                        const ordinalSuffix = (day: number) => {
                          switch (day) {
                            case 1:
                            case 21:
                            case 31:
                              return "st";
                            case 2:
                            case 22:
                              return "nd";
                            case 3:
                            case 23:
                              return "rd";
                            default:
                              return "th";
                          }
                        };

                        const formattedDate = `${date.toLocaleString(
                          "default",
                          {
                            month: "short",
                          }
                        )} ${day}${ordinalSuffix(
                          day
                        )}, ${date.getFullYear()} | ${date.toLocaleString(
                          "default",
                          {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        )}`;

                        return formattedDate;
                      })()}
                    </p>
                  </div>
                  <div className="w-1/5">
                    <h4 className="text-white text-right">
                      {`$${
                        payout.transactionType === "gift-card-transactions"
                          ? payout.tradeValue
                              .reduce(
                                (total: any, item: { amount: any }) =>
                                  total + item.amount,
                                0
                              )
                              .toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                          : payout.transactionType === "deex-crypto-withdrawals"
                          ? payout.amount?.toFixed(2)
                          : payout?.usdtAmount?.toFixed(2)
                      }`}
                    </h4>
                    {payout.transactionType === "crypto-deposit" ||
                    payout.transactionType === "deex-crypto-withdrawals" ? (
                      <div className="text-white text-[15px] w-full mt-2 whitespace-nowrap flex justify-end  overflow-hidden">
                        <h4 className="text-[#03C668] flex justify-center items-center w-4/5 bg-[#013019] tracking-wider h-[26px] rounded-full text-[9px] font-bold">
                          COMPLETED
                        </h4>
                      </div>
                    ) : (
                      <div className="text-white text-[15px] w-full mt-2 whitespace-nowrap flex justify-end  overflow-hidden">
                        <h4
                          className={
                            payout.status === "PENDING"
                              ? "text-[#CB9200] flex justify-center items-center w-4/5 bg-[#181818] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                              : payout.status === "IN PROGRESS"
                              ? "text-[#60B7F6] flex justify-center items-center w-4/5 bg-[#181818] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                              : payout.status === "COMPLETED"
                              ? "text-[#03C668] flex justify-center items-center w-4/5 bg-[#0E2B1F] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                              : ""
                          }
                        >
                          {payout.status}
                        </h4>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-6 w-full h-[1px] bg-[#273037]" />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col w-full justify-center mt-16 lgss:mt-24 items-center">
            <img src={noTransaction} alt="" />
            <h4 className="font-bold text-[#869AA9] pt-2 text-[14px]">
              No recent order
            </h4>
            <p className=" text-[#869AA9] pt-2 text-[12px]">
              All your orders will show up here
            </p>
          </div>
        )}
      </div>
      {payouts.length >= 1 && location.pathname !== "/dashboard" ? (
        <div className="flex gap-4 pb-10 justify-end items-center">
          <h4 className="font-bold font-sora text-[14px] text-white">
            Page {currentpage} of {totalPages}
          </h4>
          <button className="flex justify-center items-center">
            <BsArrowLeftSquare
              onClick={() => {
                if (page > 1) {
                  setPage((prev) => prev - 1);
                }
              }}
              className="text-3xl cursor-pointer text-[#C6D0D7] font-bold"
            />
          </button>

          <button>
            <BsArrowRightSquare
              onClick={() => {
                if (page < totalPages) {
                  setPage((prev) => prev + 1);
                }
              }}
              className="text-3xl cursor-pointer text-[#C6D0D7]"
            />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default CryptoOrders;
