import React, { useEffect, useState } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { BiErrorCircle } from "react-icons/bi";
import axios from "axios";
import { API_URL } from "../../../constant";
import { useAuth } from "../../../context/auth-context";
import { formatAmount } from "../../../utils/FormatPerson";

const CreatePayroll = ({
  setOpenPayroll,
  setSelectedStaffs,
  selectedStaffs,
  setOpenPayrollConfirm,
}: any) => {
  const { token } = useAuth();
  const [error, setError] = useState<string>("");
  const [staffs, setStaffs] = useState<any[]>([]);
  const [staffLoader, setStaffLoader] = useState<boolean>(false);

  useEffect(() => {
    setStaffLoader(true);
    axios
      .get(`${API_URL}api/payroll/staff`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        setStaffLoader(false);
        const sortedStaff = response?.data?.data.sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setStaffs(sortedStaff);
      })
      .catch((error: any) => {
        setError(error.response?.data?.message || "Error fetching staffs");
        setStaffLoader(false);
      });
  }, [token]);

  // Handle selection of a staff
  const handleSelectStaff = (staffId: string) => {
    if (selectedStaffs.includes(staffId)) {
      setSelectedStaffs(selectedStaffs.filter((id: any) => id !== staffId));
    } else {
      setSelectedStaffs([...selectedStaffs, staffId]);
    }
  };

  // Calculate the total salary of selected staffs
  const totalSalary = staffs
    .filter((staff) => selectedStaffs.includes(staff._id))
    .reduce((total, staff) => total + staff.salary, 0);

  const getOtp = () => {
    setOpenPayroll(false);
    setOpenPayrollConfirm(true);
  };
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-start bg-black bg-opacity-60 overflow-auto pt-16 pb-16 backdrop-blur-sm">
      <div className="w-11/12 mds:w-10/12 lgss:w-[35%] transition-all p-6 ease-in duration-1000 rounded-xl bg-[#1F1F1F]">
        <div className="flex pt-4 justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Create Payroll
          </h4>
          <h4
            onClick={() => setOpenPayroll(false)}
            className="text-[#78C2F7] font-medium text-[14px] cursor-pointer"
          >
            Close
          </h4>
        </div>
        <div className="w-full mt-6 h-[350px] overflow-auto">
          {staffLoader ? (
            <div className="text-center text-white">Loading staffs...</div>
          ) : staffs?.length > 0 ? (
            staffs?.map((staff: any) => (
              <div
                key={staff?._id}
                className={`flex justify-between py-4  mt-2 px-4 ${
                  selectedStaffs?.includes(staff?._id)
                    ? "bg-[#1C2227]"
                    : "bg-transparent"
                } border border-[#141414] rounded-xl items-center`}
              >
                <div className="flex items-center gap-4">
                  <input
                    type="checkbox"
                    checked={selectedStaffs.includes(staff?._id)}
                    onChange={() => handleSelectStaff(staff?._id)}
                  />
                  <div>
                    <h4 className="text-[15px] text-white font-medium">
                      {staff?.fullName?.toUpperCase() ?? "--- ---"}
                    </h4>
                    <p className="text-[13px] text-gray-400">{staff?.role}</p>
                  </div>
                </div>
                <h4 className="text-[18px] text-white">
                  ₦{formatAmount(staff?.salary) ?? "0.00"}
                </h4>
              </div>
            ))
          ) : (
            <div className="text-center text-white">No staff found</div>
          )}
        </div>

        <div className="flex flex-col justify-center items-center mt-8">
          <h4 className="text-white font-bold text-[14px]">Total Salary</h4>
          <h4 className="text-[#A0A9AD] font-medium text-[36px] mt-2">
            ₦
            {totalSalary.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h4>
        </div>
        <div className="p-4 bg-[#FCF3E8] mt-4 w-full flex gap-2 justify-start items-start rounded-lg">
          <RiErrorWarningFill className="text-[30px] text-[#F6AD57]" />
          <h4 className="text-[14px] text-[#BE6B0A] font-medium">
            Make sure you haven’t paid manually. This action cannot be reverse.
            Alternately, you can resolve payment one after the other.
          </h4>
        </div>
        {error && (
          <div className="bg-red-600 text-white mb-4 text-[13px] rounded-xl flex gap-2 items-center px-2 font-semibold mt-4">
            <BiErrorCircle className="text-xl" />
            {error}
          </div>
        )}
        <button
          onClick={getOtp}
          disabled={selectedStaffs.length === 0}
          className={`w-full rounded-xl py-3 flex justify-center items-center text-[16px] font-bold mt-6 ${
            selectedStaffs.length > 0
              ? "bg-secondary_button text-white"
              : "bg-gray-600 text-gray-200"
          }`}
        >
          Confirm Payroll
        </button>
      </div>
    </div>
  );
};

export default CreatePayroll;
