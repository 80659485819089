export const  formatNumber = (number: number)  => {
  const absNumber = Math.abs(number);
  if (absNumber >= 1e6) {
    // For millions
    return (number / 1e6).toFixed(2).replace(/\.0$/, '') + 'm';
  } else if (absNumber >= 1e3) {
    // For thousands
    return (number / 1e3).toFixed(2).replace(/\.00$/, '') + 'k';
  } else if (absNumber >= 100) {
    // For hundreds
    return (number / 100).toFixed(2).replace(/\.0$/, '') + 'h';
  } else {
    // For less than hundred
    return number;
  }
}

export function formatAmount(value: any) {
  if (value === null || value === undefined) return "0.00"; // Handle null/undefined values

  const numValue = Number(value); // Convert to a number

  // Return '0.00' for zero value
  if (numValue === 0) return "0.00";

  // For values >= 0.01, format with 2 decimal places
  if (numValue >= 0.01) {
    return numValue.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  // For small values < 0.01, handle with higher precision (up to 8 decimals)
  const precision = Math.abs(numValue) < 0.0001 ? 8 : 6; // Use 6 or 8 decimals based on magnitude
  return numValue.toLocaleString("en-US", {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
}
